$block: "survey-dialog";

.#{$block} {
    position: fixed;
    display: none;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $survey-background-color;
    z-index: 1000;

    &__wrapper {
        max-width: 600px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);

        &-inner {
            position: relative;
            overflow: hidden;
            background-image:
                linear-gradient(
                    135deg,
                    rgba(255, 255, 255, 1) 40%,
                    rgba(255, 255, 255, 0.5)
                );
        }

        &-image {
            position: absolute;
            top: 0;
            z-index: -1;
            height: 100%;
            width: 100%;

            img {
                position: absolute;
                bottom: 0;
                height: 100%;
                width: auto;
                right: 0;
            }
        }

        &-content {
            padding: 2rem;
        }
    }

    &__logo {
        text-align: right;

        img {
            max-height: 3rem;
        }
    }

    &__content {
        width: 70%;
        padding-top: 1rem;
        padding-bottom: 3rem;

        @media screen and (max-width: 1080px) {
            width: 100%;
            padding-top: 0.5rem;
            padding-bottom: 1rem;
        }
    }

    &__btn-wrapper {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }

    &__btn {
        line-height: 3rem;
        background-color: $survey-button-color-secondary;
        flex: 0 1 47%;
        text-align: center;
        cursor: pointer;
        text-transform: uppercase;
        color: $font-color !important;

        @media screen and (max-width: 1080px) {
            flex: 0 1 100%;
        }

        @media screen and (max-width: 560px) {
            font-size: 0.75rem;
        }

        &:first-of-type {
            @media screen and (max-width: 1080px) {
                margin-bottom: 0.8rem;
            }
        }

        &--blue {
            background-color: $survey-button-color-primary;
            color: white !important;
        }

        &:hover {
            text-decoration: underline;
        }
    }
}
